<template>
	<div class="header">
		<div class="header-main">
			<div class="left">
				<div class="title">海克斯康制造智能</div>
				<div class="triangle"></div>
			</div>
			<div class="right">
				<div><a target="_blank" href="http://www.hexagonmi.com.cn/">转到官网</a></div>
				<div><a target="_blank" href="https://u.hexagonmi.com.cn/account/login.aspx">转到社区</a></div>
				<div><a target="_blank" href="http://u.hexagonmi.com.cn/mall.aspx">积分兑换</a></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.header {
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	background: #666666;
	color: #ccc;
	a {
		color: #ccc;
		text-decoration: none;
	}
	.header-main {
		width: 1240px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.left {
			display: flex;
			.title {
				padding: 0 14px 0 15px;
				color: #666666;
				background: #f7f7f7;
				cursor: pointer;
			}
			.triangle {
				width: 0;
				height: 0;
				border-bottom: 30px solid #f7f7f7;
				border-right: 30px solid transparent;
			}
		}
		.right {
			display: flex;
			div {
				margin-left: 20px;
			}
		}
	}
}
</style>
