<template>
	<div class="service">
		<div class="service-main">
			<div class="service-list" @click="handleToPage(1)">
				<div><i class="iconfont iconshouhou1"></i></div>
				<div class="text">售后服务</div>
			</div>
			<div class="service-list" @click="handleToPage(2)">
				<div><i class="iconfont iconfapiaoguanli"></i></div>
				<div class="text">发票管理</div>
			</div>
			<div class="service-list" @click="handleToPage(3)">
				<div><i class="iconfont iconyijianfankui3"></i></div>
				<div class="text">订单管理</div>
			</div>
			<div class="service-list" @click="handleToPage(4)">
				<div><i class="iconfont iconlipin"></i></div>
				<div class="text">积分兑换</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		handleToPage(type) {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				switch (type) {
					case 1:
						window.open(`/me/sales`, '_blank').location;
						break;
					case 2:
						window.open(`/me/invoice`, '_blank').location;
						break;
					case 3:
						window.open(`/me/order`, '_blank').location;
						break;
					case 4:
						window.open('http://u.hexagonmi.com.cn/mall.aspx', '_blank').location;
						break;
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.service-main {
	width: 1240px;
	margin: 50px auto;
	background: #f5f5f5;
	display: flex;
	.service-list {
		width: 25%;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
		cursor: pointer;
		i {
			font-size: 50px;
			color: #999999;
		}
		.text {
			font-family: 'ct';
			margin-top: 10px;
		}
	}
}
</style>
