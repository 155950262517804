<template>
    <div class="sa">
        <div class="layout-nav" v-if="menuList.length > 2">
            <div class="layout-nav-main">
                <div class="one-main">
                    <div
                        :class="['item', { ll: !item.nva }]"
                        v-for="(item, index) in menuList"
                        :key="index"
                    >
                        {{ item.Name }}
                        <div
                            v-if="item.children.length >= 2"
                            :style="[
                                {
                                    left:
                                        item.children.length >= 3
                                            ? '-245px'
                                            : '-145px'
                                }
                            ]"
                            :class="[
                                'item-two-main',
                                { ssa: item.children.length > 0 }
                            ]"
                        >
                            <div
                                :class="[
                                    'item-two-main-one',
                                    { bb: item.children.length <= 1 }
                                ]"
                                v-for="(j, ji) in item.children"
                                :key="ji"
                                @click.stop="go1(j)"
                            >
                                <div
                                    :class="{
                                        aa: item.children.length >= 1,
                                        cc: item.children.length <= 1
                                    }"
                                    style="padding: 13px 0;"
                                >
                                    {{ j.Name }}
                                </div>
                                <div v-for="(k, ki) in j.children" :key="ki">
                                    <div
                                        v-if="j.children.length > 0"
                                        class="item-three-main-one"
                                        @click.stop="go2(k)"
                                    >
                                        {{ k.Name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            :style="[
                                {
                                    left: '-45px'
                                }
                            ]"
                            :class="[
                                'item-two-main',
                                { ssa: item.children.length > 0 }
                            ]"
                        >
                            <div
                                :class="[
                                    'item-two-main-one',
                                    { bb: item.children.length <= 1 }
                                ]"
                                v-for="(j, ji) in item.children"
                                :key="ji"
                            >
                                <div
                                    :class="{
                                        aa: item.children.length >= 1,
                                        cc: item.children.length <= 1
                                    }"
                                    style="padding: 13px 0;"
                                    @click.stop="go1(j)"
                                >
                                    {{ j.Name }}
                                </div>
                                <div v-for="(k, ki) in j.children" :key="ki">
                                    <div
                                        v-if="j.children.length > 0"
                                        class="item-three-main-one"
                                        @click="go2(k)"
                                    >
                                        {{ k.Name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="border"></div>-->
    </div>
</template>

<script>
import { getData } from './service'

export default {
    name: 'subMenu',
    data() {
        return {
            props: {
                children: 'children',
                label: 'Name',
                index: 'ID'
            },
            menuList: []
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            await getData({ Type: 2 }).then(res => {
                for (const key in res.data.Category) {
                    res.data.Category[key].nva = false
                    for (const i in res.data.Category[key].children) {
                        for (const j in res.data.Category[key].children[i]
                            .children) {
                            res.data.Category[key].nva = true
                        }
                    }
                }

                res.data.Category = res.data.Category.reverse()
                this.menuList = [...res.data.Category, ...this.menuList]
                this.menuList = this.menuList.reverse()
            })
        },
        go2(item) {
            // console.log(item.conarray)
            switch (item.Type) {
                case 1:
                    window.open(
                        `/product-list?type=${6}&BigType=${
                            item.BigType
                        }&SmaType=${item.SmaType}&MidType=${
                            item.MidType
                        }&ids=${JSON.stringify(item.conarray)}`,
                        '_blank'
                    ).location
                    break
                case 2:
                    window.open(item.Link, '_blank').location
                    break
            }
        },
        go1(item) {
            switch (item.Type) {
                case 1:
                    window.open(
                        `/product-list?type=${6}&BigType=${
                            item.BigType
                        }&SmaType=${item.SmaType}&MidType=${item.MidType}`,
                        '_blank'
                    ).location
                    break
                case 2:
                    window.open(item.Link, '_blank').location
                    break
            }
        },
        onMenuItemClick(item) {
            if (item.ID == '/home') {
                this.$router.push({
                    path: `/`
                })
            }
            this.$emit('getmenu', item)
        }
    }
}
</script>
<style lang="less" scoped>
.aa {
    cursor: pointer;
    background: #ebebeb;
    margin-left: -1px;

    border-left: 1px solid #c8c8c8;
}

.aa:hover {
}
.bb {
    border: none !important;
}
.sa {
    position: relative;
}
.border {
    position: absolute;
    top: 31px;
    left: 0;
    width: 1024px;
    height: 2px;
    z-index: 1000;
    background: linear-gradient(90deg, #a5d867, #0097ba, #00b5c2);
}
.layout-nav {
    .layout-nav-main {
        width: 1240px;
        margin: 0 auto;
    }
}
</style>
<style scoped>
body {
    width: 100vw;
    overflow: hidden;
}
</style>
<style lang="less" scoped>
.item-three-main-one {
    background: #fff;
    padding: 13px 0;
    border: 1px solid #c8c8c8;
    margin-left: -1px;
    margin-bottom: -1px;
}
.item-three-main-one:hover {
    background: #0097ba;
    color: #fff;
}
.ssa::after {
    content: '';
    width: 0px; /*设置宽高为0，所以div的内容为空，从才能形成三角形尖角*/
    height: 0px;
    border-bottom: 10px solid #666666;
    border-left: 10px solid transparent; /*transparent 表示透明*/
    border-right: 10px solid transparent;
    position: absolute;
    top: -13px;
    left: 50%;
}
.one-main {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-family: 'lt';
    .ll {
        &:hover {
            color: #0097ba;
            .item-two-main {
                left: -55px !important;
                display: block !important;
                margin-left: 10px;
                .aa {
                    background: #fff !important;
                    border: none;
                    cursor: pointer;
                }
                .aa {
                    &:hover {
                        background: #0097ba !important;
                        color: #fff !important;
                    }
                }
                .item-two-main-one {
                    border: none;
                    border-bottom: 1px solid #c8c8c8;
                }
            }
        }
    }
    .item {
        width: 130px;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        position: relative;
        z-index: 2000;

        &:hover {
            color: #0097ba;
            .item-two-main {
                display: flex;
            }
        }
    }

    .item-two-main {
        display: flex;
        position: absolute;
        top: 32px;
        left: 0;
        background: #fff;
        display: none;
        z-index: 2000;
        border-top: 5px solid #666666;

        .item-two-main-one {
            width: 200px;
            text-align: center;
            background: #fff;
            border-top: 1px solid #c8c8c8;
            border-bottom: 1px solid #c8c8c8;
            color: #666666;
        }
    }
}
</style>
