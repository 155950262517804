<template>
	<div class="layout-footer">
		<div class="layout-footer-main">
			<div class="layout-footer-list">
				<el-row>
					<el-col :span="4" style="margin-right:50px" v-for="(item, index) in list" :key="index">
						<div class="title">{{ item.title }}</div>
						<div class="item" v-for="j in item.children" :key="j.title">
							<a v-if="j.href" :href="j.href" target="_blank">{{ j.title }}</a>
							<div v-if="!j.href">
								<el-popover placement="top-start" title="" width="200" trigger="click" :content="j.content">
									<span slot="reference">{{ j.title }}</span>
								</el-popover>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="phone" style="margin-left: 50px;">400-658-0400</div>
						<div class="date" style="margin-left: 15px;">周一至周日 9:00AM - 8:00PM</div>
						<div style="margin-left: 30px;"><img class="code" src="../../../assets/code.jpg" alt="" /></div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="hex-new-footer">
			<p class="pull-left"><a href="/">©2022 海克斯康制造智能</a></p>
			<ul class="list-inline pull-left">
				<li><a href="http://www.hexagon.com.cn" target="_blank">海克斯康大中华区</a></li>
				<li><a href="http://www.hexagonmi.com.cn/CMS/nd-2152.aspx">关于海克斯康制造智能</a></li>
				<li><a href="http://www.hexagonmi.com.cn/CMS/nd-2157.aspx">全球网络</a></li>
				<li><a href="http://www.hexagonmi.com.cn/CMS/nd-2156.aspx">中国方案中心</a></li>
				<li><a href="http://hexagonmi.zhiye.com/">招聘官网</a></li>
				<li><a href="JavaScript:;">热线4006580400</a></li>
			</ul>
			<p class="pull-left">
				<a target="_blank" href="http://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">鲁ICP备13003703号</a>
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202000025" target="_blank" title="鲁公网安备 37021202000025号">鲁公网安备 37021202000025号</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					title: '企业简介',
					children: [
						{
							title: '企业介绍',
							href: 'http://www.hexagonmi.com.cn/Portal/AboutUs.aspx'
						},
						{
							title: '海克斯康大中华区',
							href: 'http://www.hexagon.com.cn'
						},
						{
							title: '全球布局',
							href: 'http://www.hexagonmi.com.cn/CMS/nd-2157.aspx'
						},
						{
							title: '中国方案中心',
							href: 'http://www.hexagonmi.com.cn/CMS/nd-2156.aspx'
						}
					]
				},
				{
					title: '商城服务',
					children: [
						{
							title: '售后政策',
							content: '海克斯康商城为海克斯康大中华区官方商城，所销售产品享受线下商品同等售后政策'
						},
						{
							title: '价格保护',
							content:
								'该政策仅限于海克斯康商城线上销售产品。支持价格保护的时间是7天，以物流签收时间为准；价格保护截止时间精确到秒。例如：如果订单物流签收时间为3月10日12:00:00，那么可支持价格保护的时间截止到3月17日12:00:00'
						},
						{
							title: '退款说明',
							content: '如取消订单或退货退款，可以在我的订单列表面页，发起退款流程；经专属客服人员审核通过后，将返款至原支付账户'
						},
						{
							title: '退换货说明',
							content: '如商品出现质量问题，可以在我的订单列表面页，发起退货流程；和专属客服人员沟通，经审核通过后，可以退换货'
						}
					]
				},
				{
					title: '购物指南',
					children: [
						{
							title: '配送范围',
							content: '无限制；配送时效性取决于配送目的地以及物流公司情况。具体物流信息可以再我的订单面页查询'
						}
					]
				},
				{
					title: '相关链接',
					children: [
						{
							title: '社区论坛',
							href: 'http://u.hexagonmi.com.cn/post.aspx'
						},
						{
							title: '海克斯康大学',
							href: 'http://u.hexagonmi.com.cn/'
						}
					]
				}
			]
		};
	}
};
</script>

<style lang="less" scoped>
a {
	color: #cccccc;
	text-decoration: none;
}

.layout-footer {
	background: #666666;

	.layout-footer-main {
		width: 1240px;
		margin: 0 auto;
		padding: 30px 0;

		.title {
			font-family: 'ct';
			font-size: 16px;
			color: #fff;
			margin-bottom: 20px;
		}

		.item {
			font-size: 14px;
			color: #cccccc;
			margin: 10px 0;
			cursor: pointer;
		}

		.phone {
			font-family: 'ct';
			font-size: 22px;
			text-align: center;
			color: #fff;
		}

		.date {
			color: #cccccc;
			font-size: 14px;
			margin-top: 10px;
			text-align: center;
		}

		.code {
			width: 150px;
			height: 150px;
			margin-top: 10px;
			margin-left: 30px;
		}
	}

	.hex-new-footer {
		background-color: #666666;
		padding: 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.pull-left {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.hex-new-footer p,
	.hex-new-footer a {
		color: #cccccc;
		font-size: 14px;
		margin: 0;
	}

	.hex-new-footer ul {
		margin: 0 60px;
	}
	.hex-new-footer ul li {
		padding: 0 8px;
	}
	.hex-new-footer ul li a {
		position: relative;
		line-height: 1.75;
	}

	.hex-new-footer ul li:first-child a::after {
		display: none;
	}

	.hex-new-footer ul li a::after {
		content: ' ';
		width: 1px;
		height: 11px;
		background-color: #cccccc;
		display: block;
		position: absolute;
		top: 5px;
		left: -8px;
	}
}
</style>
