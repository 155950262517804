import {
	post
} from '@/http'
export function getExOrderDetail(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/GetExOrderDetail', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
export function getChatRoom(data) {
	return new Promise((resolve, reject) => {
		post('/Users/GetChatRoom', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 获取用户基本信息
export function getUserInfo(data) {
	return new Promise((resolve, reject) => {
		post('/Users/GetUserInfo', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
// 未登录获取客服信息
export function getNoLoginChatRoom(data) {
	return new Promise((resolve, reject) => {
		post('/Users/GetNoLoginChatRoom', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
export function getProductDetail(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetProductDetail', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export function getOrderDetail(data) {
	return new Promise((resolve, reject) => {
		post('/Order/GetOrderDetail', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
