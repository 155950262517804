<template>
	<div class="search" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="信息获取中,请稍后">
		<div class="search-main">
			<div class="left" @click="handleToHome">
				<div><img src="../../../assets/logo.png" alt="" /></div>
				<div class="title">商城</div>
			</div>
			<div class="center">
				<input type="text" v-model="keyword" @keyup.enter="search" placeholder="请输入商品名称/编号" />
				<i class="iconfont iconsearch" style="cur" @click.stop="search"></i>
			</div>
			<div class="right">
				<div class="cart" @click="toCart">
					(
					<span>{{ cardNumber }}</span>
					) 件商品
					<span class="money">￥{{ present.toFixed(2) }}</span>
					<i class="iconfont icongouwucheman"></i>
				</div>
				<div class="news">
					<el-badge v-if="msgCount > 0" is-dot class="item"><i class="iconfont iconlingdang1" style="cursor: pointer;" @click="handleToNews"></i></el-badge>
					<i class="iconfont iconlingdang1" style="cursor: pointer;" @click="handleToNews" v-else></i>
				</div>
				<div class="user">
					<i class="iconfont icontouxiang" v-if="!isLogin" @click="toUser"></i>
					<el-dropdown @command="command" v-if="isLogin">
						<span class="el-dropdown-link"><el-image class="userpic" :src="userpic" @error="error" fit="none"></el-image></span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="user">个人中心</el-dropdown-item>
							<el-dropdown-item command="out">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { addCart } from '../../../views/commodity/service';
import { getUserInfo, getNoReadMsgCount, checkOut } from './service';
import { getMyCart, getNoLineCart } from '../../../views/cart/service';
import { mapState } from 'vuex';
import { Loading } from 'element-ui';

export default {
	data() {
		return {
			userpic: '',
			pic: FILE_URL,
			cardNumber: 0,
			present: 0,
			msgCount: 0,
			keyword: '',
			cartList: [],
			fullscreenLoading: false
		};
	},
	computed: {
		...mapState(['add', 'isLogin'])
	},
	watch: {
		add() {
			this.present = 0;
			this.cartList = [];
			setTimeout(() => {
				this.init();
			}, 500);
		},
		cartList() {
			this.present = 0;
			for (const cartListKey in this.cartList) {
				for (const listKey in this.cartList[cartListKey].List) {
					this.present += parseFloat(this.cartList[cartListKey].List[listKey].Price) * parseFloat(this.cartList[cartListKey].List[listKey].PCount);
				}
			}
		},
		isLogin: {
			handler(n) {
				if (localStorage.getItem('userToken')) {
					this.$store.commit('setLogin', true);
				} else {
					this.$store.commit('setLogin', false);
				}
				this.init();
			},
			// deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		error() {
			this.userpic = require('../../../assets/user.png');
		},
		/* eslint-disable */
		delCookie() {
			var date = new Date();
			date.setTime(date.getTime() - 10000);
			var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
			// console.log('需要删除的cookie名字：' + keys);
			if (keys) {
				for (var i = keys.length; i--; ) document.cookie = keys[i] + '=0; expire=' + date.toGMTString() + '; path=/';
			}
		},
		command(value) {
			switch (value) {
				case 'user':
					this.$router.push({
						path: '/me/order'
					});
					break;
				case 'out':
					let str = window.location.href;

					let index = str.lastIndexOf('/');

					str = str.substring(0, index + 1);
					// console.log(str);
					// localStorage.setItem('u', '');
					// localStorage.setItem('code_verifier', '');
					// localStorage.setItem('tokenJson', '');
					// localStorage.setItem('userToken', '');
					// this.isLogin = false;
					this.clearLocalStorage();
					window.location.href =
						CIAM_HOST+'/logout?client_id='+CLIENT_ID+'&logout_redirect_uri=' +
						encodeURIComponent(URL_LOGIN);

					break;
			}
		},
		search() {
			this.$router.push({
				path: `/product-list?keyword=${this.keyword}`
			});
		},
		handleToNews() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				this.$router.push({
					path: `/me/news`
				});
			}
		},
		async init() {
			// 已经登陆请求接口数据
			if (this.isLogin) {
				let cartList = this.getCartStorage();
				await cartList.forEach(function(value, key) {
					addCart({
						Code: value.code,
						Count: value.count
					});
				});

				let IsVip = false;
				// 获取用户信息
				getUserInfo().then(res => {
					if (res.data.IsVip == 1) {
						IsVip = true;
					} else {
						IsVip = false;
					}
					if (res.data.Pictrue) {
						this.userpic = res.data.Pictrue;
					} else {
						this.userpic = require('../../../assets/user.png');
					}

					localStorage.setItem('u', res.data.UserId);
				});

				getNoReadMsgCount().then(res => {
					this.msgCount = res.data.NoReadCount;
				});

				getMyCart().then(res => {
					this.cardNumber = 0;
					for (let i in res.data) {
						res.data[i].checked = true;
						for (let j in res.data[i].List) {
							res.data[i].List[j].checked = true;
							res.data[i].List[j].Price = IsVip ? res.data[i].List[j].PLUSPrice : res.data[i].List[j].Price;
							this.present += parseFloat(res.data[i].List[j].Price) * parseFloat(res.data[i].List[j].PCount);
							this.cardNumber += res.data[i].List[j].PCount;
						}
					}
					this.cartList = res.data;
				});
				localStorage.removeItem('cartList');
				return;
			}
			// 没登录请求缓存数据
			let cartList = this.getCartStorage();
			let data = [];

			if (cartList.length) {
				cartList.forEach(v => {
					data.push(v.code);
				});
				getNoLineCart({
					BarCodes: data.join(',')
				}).then(res => {
					this.cardNumber = 0;
					for (let i in res.data) {
						for (let j in res.data[i].List) {
							res.data[i].List[j].checked = true;
							for (let x = 0; x < cartList.length; x++) {
								if (res.data[i].List[j].BarCode == cartList[x].code) {
									res.data[i].List[j].PCount = cartList[x].count;
									this.cardNumber += parseInt(res.data[i].List[j].PCount);
								}
							}
						}
					}
					this.cartList = res.data;
				});
			}
		},
		// 跳转购物车
		toCart() {
			let cartList = '';
			if (!localStorage.getItem('userToken')) {
				this.clearLocalStorage();
				// localStorage.setItem('u', '');
				cartList = this.getCartUrl();
			} else {
				cartList = '';
			}
			this.$router.push({
				path: `/cart?data=${cartList}`
			});
		},
		handleToHome() {
			this.$router.push({
				path: '/home'
			});
		},
		toUser() {
			if (!localStorage.getItem('userToken')) {
				this.LoginByCIAM();
			} else {
				this.$router.push({
					path: `/me/order`
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.userpic {
	width: 35px;
	border-radius: 50%;
}

.search {
	.search-main {
		width: 1240px;
		margin: 20px auto;
		display: flex;

		.left {
			width: 30%;
			display: flex;
			cursor: pointer;
			margin-top: 10px;
			.title {
				height: 40px;
				line-height: 40px;
				padding: 0 10px;
				margin-left: 15px;
				font-size: 22px;
				font-family: 'ct';
				color: #333333;
				border-left: 1px solid #e5e5e5;
			}
		}

		.center {
			width: 40%;
			height: 35px;
			margin-top: 12px;
			border: 1px solid #d2d2d2;

			input {
				height: 33px;
				text-indent: 1rem;
				width: 90%;
				outline: none;
				border: none;
			}

			i {
				margin-left: 20px;
				color: #0097ba;
				cursor: pointer;
			}
		}

		.right {
			width: 30%;
			line-height: 63px;
			box-sizing: border-box;
			margin-right: 30px;
			display: flex;
			justify-content: flex-end;
			.news {
				width: 40px;
				height: 35px;
				line-height: 35px;
				margin-left: 30px;
				margin-right: 18px;

				margin-top: 12px;
				color: #aaaaaa;
				i {
					font-size: 20px;
				}
			}

			.cart {
				height: 35px;
				line-height: 35px;
				margin-top: 10px;
				font-size: 12px;
				color: #666666;
				cursor: pointer;
				margin-left: 40px;

				span {
					color: #0097ba;
				}

				.money {
					font-family: 'ct';
					margin-left: 5px;
					font-size: 16px;
				}

				i {
					color: #0097ba;
					font-size: 20px;
					margin-left: 10px;
				}
			}

			.user {
				cursor: pointer;
				width: 40px;
				height: 35px;
				line-height: 35px;
				margin-top: 12px;
				padding-left: 30px;
				border-left: 1px solid #e5e5e5;
				color: #aaaaaa;
				box-sizing: border-box;
				text-align: right;

				i {
					font-size: 35px;
				}
			}
		}
	}
}
</style>
