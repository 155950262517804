import { post } from '@/http'

export function getData(data) {
    return new Promise((resolve, reject) => {
        post('/Home/GetHome', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
