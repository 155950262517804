<template>
	<div>
		<div class="layout-fixed" v-if="top">
			<div class="list" style="position: fixed;border: none;right: 0px;bottom:0 ;width: 97px;height: 239px;background-color: transparent;cursor: pointer;">
				<div slot="reference">
					<a href="javascript:;" title="客服" @click="toChat">
						<!-- <i slot="reference" class="iconfont iconshouhou"></i> -->
						<img :src="require('@/assets/kefu.gif')" alt="">
						</a>
				</div>
			</div>
			<div class="list">
				<div slot="reference">
					<el-badge :value="cardNumber" class="item">
						<a href="javascript:;" title="购物车" @click="goCart"><i class="iconfont icongouwuche-01"></i></a>
					</el-badge>
				</div>
			</div>
			<div class="list">
				<div slot="reference">
					<a href="javascript:;" title="我的足迹" @click="goFootprint"><i class="iconfont iconzuji-02"></i></a>
				</div>
			</div>
			<div class="list">
				<div slot="reference">
					<a href="javascript:;" title="我的收藏" @click="goCollection"><i class="iconfont icontubiaozhizuomoban-04"></i></a>
				</div>
			</div>
			<div class="list">
				<el-popover class="popover" placement="left-start" width="100" trigger="hover" title="" content="">
					<div slot="reference">
						<a href="javascript:;" title="手机商城"><i class="iconfont iconshouji1"></i></a>
					</div>
					<img style="width:150px" src="../../../assets/code.jpg" alt="" srcset="" />
				</el-popover>
			</div>
			<div class="list" @click="goTop">
				<div slot="reference">
					<a href="javascript:;" title="返回顶部"><i class="iconfont iconzhiding"></i></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getChatRoom, getUserInfo, getNoLoginChatRoom, getOrderDetail, getProductDetail, getExOrderDetail } from './service';
import { addCart } from '../../../views/commodity/service';
import { getNoReadMsgCount } from '../layout-search/service';
import { getMyCart, getNoLineCart } from '../../../views/cart/service';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			top: true,
			cardNumber: 0,
			pic: FILE_URL
		};
	},
	computed: {
		...mapState(['add', 'isLogin'])
	},
	watch: {
		add() {
			this.present = 0;
			this.cartList = [];
			setTimeout(() => {
				this.init();
			}, 500);
		},
		cartList() {
			this.present = 0;
			for (const cartListKey in this.cartList) {
				for (const listKey in this.cartList[cartListKey].List) {
					this.present += parseFloat(this.cartList[cartListKey].List[listKey].Price) * parseFloat(this.cartList[cartListKey].List[listKey].PCount);
				}
			}
		},
		isLogin: {
			handler(n) {
				if (localStorage.getItem('userToken')) {
					this.$store.commit('setLogin', true);
				} else {
					this.$store.commit('setLogin', false);
				}
				this.init();
			},
			// deep: true,
			immediate: true
		}
	},
	mounted() {
		// this.$nextTick(()=>{
		// 	if (localStorage.getItem('userToken')) {
		// 		this.$store.commit('setLogin', true)
		// 	} else {
		// 		this.$store.commit('setLogin', false)
		// 	}
		// 	this.init();
		// })
		// var THIS = this
		// window.addEventListener('scroll', function() {
		//     //监听滚动
		//     var scrollTop =
		//         document.documentElement.scrollTop || document.body.scrollTop
		//     if (scrollTop >= 408) {
		//         //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
		//         THIS.top = true
		//     } else {
		//         THIS.top = false
		//     }
		// })
	},
	methods: {
		async init() {
			// 已经登陆请求接口数据
			if (this.isLogin) {
				let cartList = this.getCartStorage();
				await cartList.forEach(function(value, key) {
					addCart({
						Code: value.code,
						Count: value.count
					});
				});
				let IsVip = false;
				// 获取用户信息
				getUserInfo().then(res => {
					if (res.data.IsVip == 1) {
						IsVip = true;
					} else {
						IsVip = false;
					}
					this.userpic = res.data.Pictrue;
					localStorage.setItem('u', res.data.UserId);
					// this.setUserInYsf(res.data);
				});

				getNoReadMsgCount().then(res => {
					this.msgCount = res.data.NoReadCount;
				});

				getMyCart().then(res => {
					this.cardNumber = 0;
					for (let i in res.data) {
						res.data[i].checked = true;
						for (let j in res.data[i].List) {
							res.data[i].List[j].checked = true;
							res.data[i].List[j].Price = IsVip ? res.data[i].List[j].PLUSPrice : res.data[i].List[j].Price;
							this.present += parseFloat(res.data[i].List[j].Price) * parseFloat(res.data[i].List[j].PCount);
							this.cardNumber += res.data[i].List[j].PCount;
						}
					}
					this.cartList = res.data;
				});
				localStorage.removeItem('cartList');
				return;
			}
			// 没登录请求缓存数据

			let cartList = this.getCartStorage();

			let data = [];

			if (cartList.length) {
				cartList.forEach(v => {
					data.push(v.code);
				});
				getNoLineCart({
					BarCodes: data.join(',')
				}).then(res => {
					this.cardNumber = 0;
					for (let i in res.data) {
						for (let j in res.data[i].List) {
							res.data[i].List[j].checked = true;
							for (let x = 0; x < cartList.length; x++) {
								if (res.data[i].List[j].BarCode == cartList[x].code) {
									res.data[i].List[j].PCount = cartList[x].count;
									this.cardNumber += parseInt(res.data[i].List[j].PCount);
								}
							}
						}
					}
					this.cartList = res.data;
				});
			}
		},
		// setUserInYsf(userInfo) {
		// 	let t = setTimeout(() => {
		// 		this.ysf('config', {
		// 			uid: userInfo.UserId,
		// 			name: userInfo.TrueName,
		// 			mobile: userInfo.AccountMobile,
		// 			// Pictrue: userInfo.Pictrue,
		// 			level: userInfo.IsVip ? userInfo.IsVip : 0, // vip级别
		// 			data: JSON.stringify([
		// 				{
		// 					key: 'CiamUserId',
		// 					value: userInfo.CiamUserId,
		// 					label: '用户ID'
		// 				},
		// 				// {
		// 				// 	key: 'Pictrue',
		// 				// 	value: userInfo.Pictrue,
		// 				// 	label: '头像'
		// 				// }
		// 			]),
		// 			success: function() {
		// 				isConfig = true;
		// 			}
		// 		});
		// 		clearTimeout(t);
		// 	}, 1000);
		// },
		// 购物车
		goCart() {
			let cartList = '';
			if (!localStorage.getItem('userToken')) {
				cartList = this.getCartUrl();
			} else {
				cartList = '';
			}
			this.$router.push({
				path: `/cart?data=${cartList}`
			});
		},
		// 客服对话
		async toChat() {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
		// 	if (!localStorage.getItem('userToken')) {
		// 		getNoLoginChatRoom().then(res => {
		// 			if (res.code == 0) {
		// 				this.$message.error(res.msg);
		// 				return;
		// 			}
		// 			if (this.$route.name == 'Commodity') {
		// 				getProductDetail({ PID: this.$route.query.pid })
		// 					.then(pres => {
		// 						this.ysf('product', {
		// 							show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
		// 							title: pres.data.ProName,
		// 							desc: pres.data.Description,
		// 							picture: FILE_URL + pres.data.Picture,
		// 							note: '价格：￥' + pres.data.Price,
		// 							url: window.location.href,
		// 							success: () => {
		// 								// 成功回调
		// 								this.ysf('open');
		// 							},
		// 							error: () => {
		// 								// 错误回调
		// 								this.ysf('open');
		// 							}
		// 						});
		// 					})
		// 					.catch(() => {
		// 						this.ysf('open');
		// 					});
		// 			} else if (this.$route.name == 'orderDetails') {
		// 				getOrderDetail({
		// 					OrderID: this.$route.query.OrderID
		// 				})
		// 					.then(ores => {
		// 						let proName = [];
		// 						ores.data.DetailList.forEach(v => {
		// 							proName.push(v.ProName);
		// 						});
		// 						this.ysf('product', {
		// 							show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
		// 							title: '订单号：' + ores.data.OrderID,
		// 							desc: '订单商品：' + proName.join('/'),
		// 							picture: FILE_URL + ores.data.DetailList[0].ProPictrue,
		// 							note: '实付金额：￥' + ores.data.TotalPrice,
		// 							success: () => {
		// 								// 成功回调
		// 								this.ysf('open');
		// 							},
		// 							error: () => {
		// 								// 错误回调
		// 								this.ysf('open');
		// 							}
		// 						});
		// 					})
		// 					.catch(() => {
		// 						this.ysf('open');
		// 					});
		// 			} else {
		// 				this.ysf('open');
		// 			}
		// 		});
		// 	} else {
		// 		let ress = await getUserInfo();

		// 		if (this.$route.name == 'Commodity') {
		// 			getProductDetail({ PID: this.$route.query.pid })
		// 				.then(pres => {
		// 					this.ysf('product', {
		// 						show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
		// 						title: pres.data.ProName,
		// 						desc: pres.data.Description,
		// 						picture: FILE_URL + pres.data.Picture,
		// 						note: '价格：￥' + pres.data.Price,
		// 						url: window.location.href,
		// 						success: () => {
		// 							// 成功回调
		// 							this.ysf('open');
		// 						},
		// 						error: () => {
		// 							// 错误回调
		// 							this.ysf('open');
		// 						}
		// 					});
		// 				})
		// 				.catch(() => {
		// 					this.ysf('open');
		// 				});
		// 		} else if (this.$route.name == 'orderDetails') {
		// 			getOrderDetail({
		// 				OrderID: this.$route.query.OrderID
		// 			})
		// 				.then(ores => {
		// 					let proName = [];
		// 					ores.data.DetailList.forEach(v => {
		// 						proName.push(v.ProName);
		// 					});
		// 					this.ysf('product', {
		// 						show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
		// 						title: '订单号：' + ores.data.OrderID,
		// 						desc: '订单商品：' + proName.join('/'),
		// 						picture: FILE_URL + ores.data.DetailList[0].ProPictrue,
		// 						note: '实付金额：￥' + ores.data.TotalPrice,
		// 						tags: [
		// 							{
		// 								label: '查询订单',
		// 								focusIframe: '订单',
		// 								data: { orderId: ores.data.OrderID }
		// 							}
		// 						],
		// 						success: () => {
		// 							// 成功回调
		// 							this.ysf('open');
		// 						},
		// 						error: () => {
		// 							// 错误回调
		// 							this.ysf('open');
		// 						}
		// 					});
		// 				})
		// 				.catch(() => {
		// 					this.ysf('open');
		// 				});
		// 		} else if (this.$route.name == 'goodsDetails') {
		// 			getExOrderDetail({
		// 				ExOrderID: this.$route.query.OrderID
		// 			})
		// 				.then(ores => {
		// 					let ExTotalPrice = ores.data.ExTotalPrice;
		// 					let j = ores.data.ExOrderDataList[0].ItemList[0].ProductOrderDetai;
		// 					let item = ores.data.ExOrderDataList[0];
		// 					let ExOrder = item.ExOrder;
		// 					this.ysf('product', {
		// 						show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
		// 						title: '退款编号：' + ExOrder.ExOrderID,
		// 						desc:
		// 							'退款商品：' +
		// 							j.ProName +
		// 							'退款件数：' +
		// 							item.DetailInfoList[0].ExOrderDetail.PCount +
		// 							'件 / 退款金额：' +
		// 							ExTotalPrice +
		// 							' / 退款方式：原支付方式退回 / 货物状态：' +
		// 							(ExOrder.TrackType == 1 ? '未收到货' : '已收到货'),
		// 						picture: FILE_URL + j.ProPictrue,
		// 						note: '退款原因：' + (ExOrder.Remark ? ExOrder.Remark : '无'),
		// 						success: () => {
		// 							// 成功回调
		// 							this.ysf('open');
		// 						},
		// 						error: () => {
		// 							// 错误回调
		// 							this.ysf('open');
		// 						}
		// 					});
		// 				})
		// 				.catch(() => {
		// 					this.ysf('open');
		// 				});
		// 		} else {
		// 			this.ysf('open');
		// 		}
		// 	}
		
		},
		goFootprint() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				this.$router.push({
					path: `/me/footprint`
				});
			}
		},
		goCollection() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				this.$router.push({
					path: `/me/collection`
				});
			}
		},
		goTop() {
			var timer = setInterval(function() {
				let osTop = document.documentElement.scrollTop || document.body.scrollTop;
				let ispeed = Math.floor(-osTop / 5);
				document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
				this.isTop = true;
				if (osTop === 0) {
					clearInterval(timer);
				}
			}, 10);
		}
	}
};
</script>

<style lang="less" scoped>
.layout-fixed {
	position: fixed;
	right: 20px;
	top: 170px;

	a {
		color: #666666;
	}

	.list {
		width: 50px;
		height: 50px;
		border: 1px solid #eeeeee;
		background: #ffffff;
		border-radius: 4px;
		text-align: center;
		line-height: 50px;
		margin: 5px 0;

		i {
			font-size: 28px;
			cursor: pointer;
		}
		a {
			text-decoration: none;
		}
	}
}
</style>
