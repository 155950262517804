var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sa"},[(_vm.menuList.length > 2)?_c('div',{staticClass:"layout-nav"},[_c('div',{staticClass:"layout-nav-main"},[_c('div',{staticClass:"one-main"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:index,class:['item', { ll: !item.nva }]},[_vm._v(" "+_vm._s(item.Name)+" "),(item.children.length >= 2)?_c('div',{class:[
                                'item-two-main',
                                { ssa: item.children.length > 0 }
                            ],style:([
                                {
                                    left:
                                        item.children.length >= 3
                                            ? '-245px'
                                            : '-145px'
                                }
                            ])},_vm._l((item.children),function(j,ji){return _c('div',{key:ji,class:[
                                    'item-two-main-one',
                                    { bb: item.children.length <= 1 }
                                ],on:{"click":function($event){$event.stopPropagation();return _vm.go1(j)}}},[_c('div',{class:{
                                        aa: item.children.length >= 1,
                                        cc: item.children.length <= 1
                                    },staticStyle:{"padding":"13px 0"}},[_vm._v(" "+_vm._s(j.Name)+" ")]),_vm._l((j.children),function(k,ki){return _c('div',{key:ki},[(j.children.length > 0)?_c('div',{staticClass:"item-three-main-one",on:{"click":function($event){$event.stopPropagation();return _vm.go2(k)}}},[_vm._v(" "+_vm._s(k.Name)+" ")]):_vm._e()])})],2)}),0):_c('div',{class:[
                                'item-two-main',
                                { ssa: item.children.length > 0 }
                            ],style:([
                                {
                                    left: '-45px'
                                }
                            ])},_vm._l((item.children),function(j,ji){return _c('div',{key:ji,class:[
                                    'item-two-main-one',
                                    { bb: item.children.length <= 1 }
                                ]},[_c('div',{class:{
                                        aa: item.children.length >= 1,
                                        cc: item.children.length <= 1
                                    },staticStyle:{"padding":"13px 0"},on:{"click":function($event){$event.stopPropagation();return _vm.go1(j)}}},[_vm._v(" "+_vm._s(j.Name)+" ")]),_vm._l((j.children),function(k,ki){return _c('div',{key:ki},[(j.children.length > 0)?_c('div',{staticClass:"item-three-main-one",on:{"click":function($event){return _vm.go2(k)}}},[_vm._v(" "+_vm._s(k.Name)+" ")]):_vm._e()])})],2)}),0)])}),0)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }