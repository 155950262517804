import {
	post,
	get
} from '@/http'

// 获取用户基本信息
export function getUserInfo(data) {
	return new Promise((resolve, reject) => {
		post('/Users/GetUserInfo', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 未读消息数量
export function getNoReadMsgCount(data) {
	return new Promise((resolve, reject) => {
		post('/MsgCenter/GetNoReadMsgCount', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export function checkOut(data) {
	return new Promise((resolve, reject) => {
		post('Users/CheckOut', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
